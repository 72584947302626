<template>
  <div>
    <v-card width="100%" class="pa-6">
      <v-container v-if="allQuestions.length !== 0">
        <div class="mt-3 mb-3" v-for="item in allQuestions" :key="item.id">
          <v-row class="text-bold">
            {{ item.question }}
          </v-row>
          <v-row v-if="editQuestion !== item.id">
            <p v-if="userPreferences.find(x => x.question_id === item.id)" class="ml-3">
              {{ userPreferences.find(x => x.question_id === item.id).answer }}</p>
            <p @click="editQuestion = item.id" class="ml-3" v-else><a>Add an answer</a></p>
            <v-spacer></v-spacer>
            <v-btn @click="editQuestion = item.id; currentAnswer = null; useAnswer(item.id)" text
                   color="primary">
              <v-icon>mdi-square-edit-outline</v-icon>
              Edit
            </v-btn>
            <v-btn v-if="userPreferences.find(x => x.question_id === item.id)"
                   @click="deletePreference(userPreferences.find(x => x.question_id === item.id).id)" text color="primary" :disabled="deleteBtnLoad" :loading="deleteBtnLoad">
              <v-icon>mdi-trash-can</v-icon>
              Delete
            </v-btn>
          </v-row>
          <v-row v-if="item.type === 'essay' && editQuestion === item.id">
            <v-col cols="6">
              <v-text-field class="mt-2" v-model="currentAnswer" outlined></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-btn class="mt-4"
                     @click="userPreferences.find(x => x.question_id === item.id) ? editPreference(userPreferences.find(x => x.question_id === item.id)) : addPreference(item.id)"
                     color="primary" :loading="textBtnLoad">Submit
              </v-btn>
              <v-btn
                @click="currentAnswer = null; editQuestion = 0"
                class="ml-2 mt-4" color="primary" text>Cancel
              </v-btn>
            </v-col>
          </v-row>
          <v-radio-group v-model="radio" v-else-if="item.type === 'yesorno' && editQuestion === item.id">
            <v-row>
              <v-col cols="2">
                <v-radio color="primary" label="Yes"></v-radio>
              </v-col>
              <v-col cols="2">
                <v-radio color="primary" label="No"></v-radio>
              </v-col>
              <v-col cols="8">
                <v-btn
                  @click="userPreferences.find(x => x.question_id === item.id) ? editPreference(userPreferences.find(x => x.question_id === item.id)) : addPreference(item.id)"
                  class="ml-auto" color="primary" :loading="yesNoBtnLoad">Submit
                </v-btn>
                <v-btn
                  @click="radio = null; editQuestion = 0"
                  class="ml-2" color="primary" text>Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-radio-group>
          <v-row v-else-if="item.type === 'dropdown' && editQuestion === item.id">
            <v-col cols="6">
              <v-select v-model="selectedValue" outlined item-text="Answer" item-value="Answer"
                        :items="item.answers"></v-select>
            </v-col>
            <v-col cols="6">
              <v-btn class="mt-4"
                     @click="userPreferences.find(x => x.question_id === item.id) ? editPreference(userPreferences.find(x => x.question_id === item.id)) : addPreference(item.id)"
                     color="primary" :loading="dropBtnLoad">Submit
              </v-btn>
              <v-btn
                @click="selectedValue = null; editQuestion = 0"
                class="ml-2 mt-4" color="primary" text>Cancel
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-container>
        <v-container v-else>
          <p class="primary--text">No Questions to answer</p>
        </v-container>
    </v-card>
  </div>
</template>

<script>
import user from '@/web_services/user'

export default {
  data () {
    return {
      userPreferences: [],
      deleteBtnLoad: false,
      allQuestions: [],
      editingSports: 0,
      textBtnLoad: false,
      dropBtnLoad: false,
      yesNoBtnLoad: false,
      editingPlayerName: 0,
      editQuestion: 0,
      radio: null,
      baseRadio: null,
      selectedValue: null,
      baseSelectedValue: null,
      currentAnswer: null,
      baseAnswer: null,
      items: [
        {
          value: 1,
          name: 'haha1'
        },
        {
          value: 2,
          name: 'haha2'
        }
      ]
    }
  },
  methods: {
    useAnswer (id) {
      const preference = this.userPreferences.find(x => x.question_id === id)
      if (preference) {
        const question = this.allQuestions.find(x => x.id === preference.question_id)
        if (question.type === 'yesorno' && preference.answer === 'Yes') {
          this.radio = 0
          this.baseRadio = 0
        } else if (question.type === 'yesorno' && preference.answer === 'No') {
          this.radio = 1
          this.baseRadio = 1
        } else if (question.type === 'dropdown') {
          const answers = this.allQuestions.find(x => x.id === id).answers
          if (answers) {
            this.selectedValue = answers.find(x => x.Answer === preference.answer).Answer
            this.baseSelectedValue = answers.find(x => x.Answer === preference.answer).Answer
          }
        } else if (question.type === 'essay') {
          this.currentAnswer = preference.answer
          this.baseAnswer = preference.answer
        }
      }
    },
    deletePreference (id) {
      this.deleteBtnLoad = true
      user.deletePreference(id).then(res => {
        this.userPreferences = res.data.data
      }).catch(() => {
        this.$emit('error', 'Something went wrong')
      }).finally(() => {
        this.deleteBtnLoad = false
      })
    },
    editPreference (preference) {
      const question = this.allQuestions.find(x => x.id === preference.question_id)
      if (question) {
        if (question.type === 'yesorno' && this.radio !== this.baseRadio) {
          if (this.radio === 0) {
            this.yesNoBtnLoad = true
            const data = {
              answer: 'Yes'
            }
            user.editPreference(data, preference.id).then(res => {
              this.userPreferences = res.data.data
            }).catch(() => {
              this.$emit('error', 'Something went wrong')
            }).finally(() => {
              this.editQuestion = 0
              this.yesNoBtnLoad = false
            })
          } else if (this.radio === 1) {
            this.yesNoBtnLoad = true
            const data = {
              answer: 'No'
            }
            user.editPreference(data, preference.id).then(res => {
              this.userPreferences = res.data.data
            }).catch(() => {
              this.$emit('error', 'Something went wrong')
            }).finally(() => {
              this.editQuestion = 0
              this.yesNoBtnLoad = false
            })
          } else {
            this.editQuestion = 0
            this.yesNoBtnLoad = false
          }
        } else if (question.type === 'essay') {
          this.textBtnLoad = true
          if (this.currentAnswer !== this.baseAnswer) {
            const data = {
              answer: this.currentAnswer
            }
            user.editPreference(data, preference.id).then(res => {
              this.userPreferences = res.data.data
            }).catch(() => {
              this.$emit('error', 'Something went wrong')
            }).finally(() => {
              this.textBtnLoad = false
              this.editQuestion = 0
            })
          } else {
            this.textBtnLoad = false
            this.editQuestion = 0
          }
        } else {
          if (this.selectedValue !== this.baseSelectedValue) {
            this.dropBtnLoad = true
            const data = {
              answer: this.selectedValue
            }
            user.editPreference(data, preference.id).then(res => {
              this.userPreferences = res.data.data
            }).catch(() => {
              this.$emit('error', 'Something went wrong')
            }).finally(() => {
              this.editQuestion = 0
              this.dropBtnLoad = false
            })
          } else {
            this.editQuestion = 0
            this.dropBtnLoad = false
          }
        }
      }
    },
    addPreference (id) {
      const question = this.allQuestions.find(x => x.id === id)
      if (question) {
        if (question.type === 'yesorno') {
          const data = {}
          if (this.radio === 0) {
            data.answer = 'Yes'
          } else {
            data.answer = 'No'
          }
          user.addPreference(data, question.id).then(res => {
            this.userPreferences = res.data.data
          }).catch(() => {
            this.$emit('error', 'Something went wrong')
          }).finally(() => {
            this.editQuestion = 0
            this.radio = null
          })
        } else if (question.type === 'essay') {
          const data = { answer: this.currentAnswer }
          user.addPreference(data, question.id).then(res => {
            this.userPreferences = res.data.data
          }).catch(() => {
            this.$emit('error', 'Something went wrong')
          }).finally(() => {
            this.editQuestion = 0
            this.currentAnswer = null
          })
        } else {
          const data = { answer: this.selectedValue }
          user.addPreference(data, question.id).then(res => {
            this.userPreferences = res.data.data
          }).catch(() => {
            this.$emit('error', 'Something went wrong')
          }).finally(() => {
            this.editQuestion = 0
            this.selectedValue = 0
          })
        }
      }
    }
  },
  created () {
    user.userPreferences().then(res => {
      this.userPreferences = res.data.data
    }).catch(error => {
      this.$emit('error', error)
    })
    user.preferenceQuestions().then(res => {
      this.allQuestions = res.data.data
    }).catch(error => {
      this.$emit('error', error)
    })
  }
}
</script>
